import { BaseService } from './base';
import { AccountantExt, CompanyExt, User } from '@/models';
import { Paginated } from '@/lib/types';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import { AuthService } from '@/lib/services/index';

class AdminService extends BaseService {
  public async getCompanies(
    page: number = 1,
    q?: string,
  ): Promise<Paginated<CompanyExt>> {
    const { data } = await this.axios().get(`/admin/company`, {
      params: {
        page,
        q,
      },
    });
    data.data.items = data.data.items.map(CompanyExt.deserialize);
    return data.data;
  }

  public async getAccountants(): Promise<AccountantExt[]> {
    const { data } = await this.axios().get(`/admin/accountant`);
    return data.data.map(AccountantExt.deserialize);
  }

  public async getUsers(): Promise<User[]> {
    const { data } = await this.axios().get(`/admin/user`);
    return data.data.map(User.deserialize);
  }

  public async updateCompany(c: CompanyExt): Promise<void> {
    await this.axios().post(`/admin/company/${c.id}`, c.serialize());
  }

  public async updateAccountant(a: AccountantExt): Promise<void> {
    await this.axios().put(`/admin/accountant/${a.id}`, a.serialize());
  }

  public async createCompany(c: CompanyExt): Promise<void> {
    await this.axios().post(`/admin/company`, c.serialize());
  }

  public async createAccountant(a: AccountantExt): Promise<void> {
    await this.axios().post(`/admin/accountant`, a.serialize());
  }

  public async linkCompanyAccountant(
    a: AccountantExt,
    c: CompanyExt,
  ): Promise<void> {
    await this.axios().put(`/admin/accountant/${a.id}/${c.id}`);
  }

  public async login(subscriberId: number, type: 'company' | 'accountant') {
    try {
      const { data } = await this.axios().post('/admin/session', {
        subscriber: subscriberId,
        type,
      });

      const oldUser = store.getters['auth/user'];
      await AuthService.deauthenticate();
      await AuthService.authenticate(data.data.token);
      const newUser = store.getters['auth/user'];

      Sentry.addBreadcrumb({
        message: 'Admin login',
        category: 'auth',
        data: {
          from: oldUser,
          to: newUser,
        },
      });
    } catch (e) {
      throw new Error('Session expired');
    }
  }
}

export default new AdminService();
